import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "#212529",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M3.09045 12.99L3.09045 11.0101H11.0101L11.0101 3.09054H12.9899L12.9899 11.0101H20.9095L20.9095 12.99L12.9899 12.99V20.9096H11.0101V12.99L3.09045 12.99Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }